<template>
  <TosListVuetify :entityName="entityName" :headers="headers" :validateFunction="validate">
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-row>
          <v-col cols="3">
            <v-text-field label="Nome" v-model="slotProps.data.nome" />
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template #item.validita="slotProps">
      <span v-if="!slotProps.data.validoDa && !slotProps.data.validoA"> ND </span>
      <span v-else>
        <span v-if="slotProps.data.validoDa">
          {{ slotProps.data.validoDa | formatShortDateFormat }}
        </span>
        <span v-else> / </span>
        -
        <span v-if="slotProps.data.validoA">
          {{ slotProps.data.validoA | formatShortDateFormat }}
        </span>
        <span v-else> / </span>
      </span>
    </template>
    <template #editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content">
        <v-row>
          <v-col cols="4">
            <v-text-field :label="tLabel('Nome')" v-model="slotProps.data.nome" :error-messages="slotProps.errors.nome" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <CalendarFilter :label="tLabel('inizio_validita')" v-model="slotProps.data.validoDa" :error-messages="slotProps.errors.validoDa" />
          </v-col>
          <v-col cols="6">
            <CalendarFilter :label="tLabel('fine_validita')" v-model="slotProps.data.validoA" :error-messages="slotProps.errors.validoA" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-menu
              ref="menuInizio"
              v-model="menuInizio"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="slotProps.data.inizio"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="slotProps.data.inizio"
                  :label="tLabelRequired('Inizio')"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                color="primary"
                v-if="menuInizio"
                v-model="slotProps.data.inizio"
                full-width
                format="24hr"
                @click:minute="$refs.menuInizio.save(slotProps.data.inizio)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              ref="menuFine"
              v-model="menuFine"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="slotProps.data.fine"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="slotProps.data.fine"
                  :label="tLabelRequired('Fine')"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                color="primary"
                v-if="menuFine"
                v-model="slotProps.data.fine"
                full-width
                format="24hr"
                @click:minute="$refs.menuFine.save(slotProps.data.fine==='00:00'?'24:00':slotProps.data.fine)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>
    </template>
  </TosListVuetify>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../mixins/FormatsMixins";
import CalendarFilter from "../../components/anagrafiche/CalendarFilter";
import TimeAdapter from "../../components/anagrafiche/TimeAdapter";

export default {
  name: "Turni",
  data() {
    return {
      entityName: "turni",
      menuInizio: false,
      menuFine: false,
      headers: [
        { text: "", value: "action" },
        { text: "Nome", value: "nome" },
        { text: "Validità", value: "validita" },
        { text: "Inizio", value: "inizio" },
        { text: "Fine", value: "fine" },
      ],
    };
  },
  components: {
    TosListVuetify: TosListVuetify,
    CalendarFilter: CalendarFilter,
    TimeAdapter: TimeAdapter,
  },
  mixins: [FormatsMixins],
  methods: {
    validate(item) {
      let errors = {};
      // TODO
      return errors;
    },
  },
};
</script>
